import join from 'url-join'
import { createAction, asyncAction } from '@nike/redux-action-utils'

import config from '../config.js'
import http from '../util/http.js'

const apiUrl = join.bind(join, config.apiUrl, 'v1')

export const getTransaction = asyncAction({
  baseName: 'TRANSACTION_GET',
  action:
    (action) =>
    ({ traceId }) =>
    (dispatch) => {
      dispatch(action.start())

      return http
        .get(apiUrl('logs', traceId))
        .then((response) => {
          dispatch(
            action.success({
              items: response.items,
              traceId,
            })
          )
        })
        .catch((error) => {
          dispatch(
            action.error({
              error,
              traceId,
            })
          )
        })
    },
})

export const clearTransaction = createAction('TRANSACTION_CLEAR', {
  noPayload: true,
})
