import { Icon, Link } from '@nike/eds'

export const ErrorBoundary = (err, info) => {
  console.warn(err)
  console.warn(info)
  return (
    <div className='entitlements-error-boundary'>
      <h1 className='eds-type--display-4'>Oops!</h1>
      <p className='eds-type--body-2 eds-spacing--m-16'>
        It looks like something went wrong. Sorry for the inconvenience.
      </p>
      <p className='eds-type--body-2 eds-spacing--m-16'>
        Please{' '}
        <Link
          href={window.location.href}
          onClick={(e) => {
            e.preventDefault()
            window.location.reload()
          }}
        >
          reload the page
        </Link>
        . Reach out to{' '}
        <Link
          rel='noopener noreferrer'
          target='blank'
          href='https://nikedigital.slack.com/archives/CC5FN7AMV'
        >
          #platformconsole
        </Link>{' '}
        if this issue persists.
      </p>
      <Icon name='NikeSwoosh' size='l' color='rgb(237,111,45)' />
    </div>
  )
}
