import { connect } from 'react-redux'
import React from 'react'
import { notifyOffline } from '../offline/actions.js'
import { isOffline } from '../offline/reducer.js'
import { Icon, Text } from '@nike/eds'

const OfflineBanner = ({ offline }) =>
  offline ? (
    <div className='bg-accent offline-section text-white'>
      <Icon name='AlertCircleFilled' size='small' />
      <Text className='mr-1' font='display-5' as='span'>
        OFFLINE MODE
      </Text>
      <Text font='body-2' as='span'>
        Functionality may be limited
      </Text>
    </div>
  ) : null

export default connect(
  (state) => ({
    offline: isOffline(state),
  }),
  { notifyOffline }
)(OfflineBanner)
