import React from 'react'

import { selectIsLoggedIn, selectLoginComplete, useAuthStore } from '@nike/aegis-auth-react'
import { Text } from '@nike/eds'

import { client } from './client.js'

import { PageSpinner, Button } from '../components/index.js'

function LoginRequired({ children }) {
  const loginFinished = useAuthStore(selectLoginComplete)
  const isLoggedIn = useAuthStore(selectIsLoggedIn)
  if (!loginFinished) return <PageSpinner />
  if (!isLoggedIn) {
    return (
      <div className={styles.loginMessage}>
        <Button onClick={() => client.login()}>Login</Button>
        <Text font={'body-3'}>Your session has expired. Please login.</Text>
      </div>
    )
  }

  return React.Children.only(children)
}

const requireLogin = () => (WrappedComponent) => (props) => {
  return (
    <LoginRequired>
      <WrappedComponent {...props} />
    </LoginRequired>
  )
}
export { LoginRequired }
export default requireLogin
