import React from 'react'

import Link from './Link.js'
import { IconButton, Icon } from '@nike/eds'
import { Button } from '../components/index.js'

const LinkButton = ({ to, icon, label, title, iconOnly, ...props }) => (
  <Link to={to} title={title}>
    {iconOnly ? (
      <IconButton name={icon} />
    ) : (
      <Button {...props}>
        {label} {icon ? <Icon name={icon} /> : null}
      </Button>
    )}
  </Link>
)

export default LinkButton
