import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { animated } from 'react-spring'
import { geoPath, geoGraticule } from 'd3-geo'
import { merge } from 'topojson-client'
import { Spinner } from '@nike/eds'

import { getHealth } from './actions.js'
import RegionTooltip from './RegionTooltip.js'
import { selectRegionalStatus } from './reducer.js'
import { environments, colors } from '../enums.js'
import boundaries from './boundaries-no-nz.json'
import awsRegions from './aws-regions.geo.json'
import nellHammerProjection from './projections/nellHammer.js'
import { useSpringLoop } from '../util/springs.js'

const filterAWSRegions = (healthcheck) => {
  let showTheseRegionsOnMap = healthcheck.map(({ healthCheckFrom }) =>
    healthCheckFrom.replace('aws-', '')
  )
  const features = awsRegions.features.filter(({ properties: { region } }) =>
    showTheseRegionsOnMap.includes(region)
  )
  return { type: 'FeatureCollection', features }
}

const mapWidth = 1000
const mapHeight = 500
const circleRadius = 16

export default function HealthCheckGrid() {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })

  const dispatch = useDispatch()
  const healthcheck = useSelector(selectRegionalStatus)
  const isProduction = useSelector((state) => state.navigation.get('isProduction'))

  const handleMouseMove = (event) => setCoordinates({ x: event.clientX, y: event.clientY })
  useEffect(() => {
    dispatch(
      getHealth({
        environment: isProduction ? environments.prod : environments.preprod,
      })
    )
  }, [dispatch, isProduction])
  const pulse = useSpringLoop({
    config: { mass: 1, tension: 280, friction: 80 },
    from: { opacity: 1, radius: circleRadius },
    to: { opacity: 0, radius: circleRadius * 4 },
  })

  const geojson = filterAWSRegions(healthcheck)
  const projection = nellHammerProjection()
  const mapPath = geoPath().projection(projection)
  const graticule = geoGraticule()

  if (healthcheck.length === 0 || Object.keys(healthcheck).length === 0) {
    return <Spinner className='spinner' />
  }

  return (
    <div onMouseMove={handleMouseMove}>
      <div id='d3-world-map'>
        <svg viewBox={` 0 0 ${mapWidth} ${mapHeight}`} preserveAspectRatio='xMinYMin meet'>
          <path className='ocean' d={mapPath({ type: 'Sphere' })} />
          <path className='graticule' d={mapPath(graticule())} />
          <path
            className='land'
            d={mapPath(merge(boundaries, boundaries.objects.land.geometries))}
          />
          <g>
            {geojson.features.map((marker, i) => (
              <RegionMarker
                key={i}
                pulse={pulse}
                x={projection(marker.geometry.coordinates)[0]}
                y={projection(marker.geometry.coordinates)[1]}
                region={marker.properties.region}
                healthcheck={healthcheck}
                coordinates={coordinates}
              />
            ))}
          </g>
        </svg>
      </div>
    </div>
  )
}

export function RegionMarker({ region, x, y, healthcheck, pulse, coordinates }) {
  const [coords, setCoords] = useState([0, 0])
  const [display, setDisplay] = useState(undefined)

  const {
    status,
    healthCheckFrom,
    result: { checks },
  } = healthcheck
    .filter(({ healthCheckFrom }) => healthCheckFrom.replace('aws-', '') === region)
    .pop()

  useEffect(() => {
    if (coords[0] === coordinates.x && coords[0] !== 0) {
      setDisplay(coords)
    } else setDisplay(undefined)
  }, [coords, coordinates])
  return (
    <>
      <animated.circle
        cx={x}
        cy={y}
        r={pulse.radius}
        fill={fillColor(status)}
        opacity={pulse.opacity}
      />
      <circle
        onMouseMove={({ pageX, pageY }) => setCoords([pageX, pageY])}
        cx={x}
        cy={y}
        r={circleRadius}
        fill={fillColor(status)}
      />
      {display && (
        <RegionTooltip
          {...{ status, healthCheckFrom, checks }}
          x={display[0]}
          y={display[1]}
          opacity={0.7}
        />
      )}
    </>
  )
}

export function fillColor(status) {
  if (!status || status === 'fail') return colors.red
  if (status === 'warn') return colors.yellow
  return colors.green
}
