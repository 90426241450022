import { Component } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'

export default class RenderInBody extends Component {
  constructor(...props) {
    super(...props)
    this.propup = null
  }

  componentDidMount() {
    this.popup = document.createElement('div')
    document.body.appendChild(this.popup)
    this._renderLayer()
  }

  componentDidUpdate() {
    this._renderLayer()
  }

  componentWillUnmount() {
    unmountComponentAtNode(this.popup)
    document.body.removeChild(this.popup)
  }

  _renderLayer = () => {
    render(this.props.children, this.popup)
  }

  render() {
    return null
  }
}
