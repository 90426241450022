import React from 'react'
import ReactDiffViewer from '@automatication/react-diff-viewer-continued'

const Diff = ({ left, right }) => {
  return (
    <div className='diff-container'>
      <ReactDiffViewer oldValue={left} newValue={right} splitView={true} />
    </div>
  )
}

export default Diff
