import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { Icon } from '@nike/eds'
import { useParams } from 'react-router-dom'
import { Tab, TabGroup, Spinner } from '@nike/eds'

import { getEntitlement, getEntitlementAudits } from './actions.js'
import { selectEntitlement, selectAuditPager } from './reducer.js'
import { selectLastSearchResult } from '../search/reducer.js'
import { selectIsProduction } from '../navigation/reducer.js'
import { PageHeader, LinkButton, PageSpinner, PageTitle } from '../components/index.js'
import { selectIsRequestPending, selectRequestError } from '../util/index.js'
import PolicyViewer from './PolicyViewer.js'
import AuditLog from './AuditLog.js'

const tabs = [
  {
    id: 'policy',
    label: 'Policy',
  },
  {
    id: 'audit',
    label: 'Audit',
  },
]

const renderRequestErrorMessage = (status) => {
  switch (status) {
    case 400:
      return '🤔 The ID you requested is invalid'
    case 401:
      return '🚫 You are not authorized to make this request'
    case 404:
      return '🙁 That entitlement could not be found'
    default:
      return '🔥 Something went wrong. Reach out to us on Slack for more information'
  }
}

const EntitlementView = (props) => {
  let { entitlement, requestError, isEntitlementPending, isAuditPending, auditPager } = props
  const [activeTab, setActiveTab] = useState('policy')

  if (!isEntitlementPending && !entitlement && !requestError) return
  if (isEntitlementPending && isAuditPending) return <PageSpinner />
  if (requestError) return renderRequestErrorMessage(requestError.status)

  entitlement = entitlement.toJS()
  auditPager = auditPager.toJS()

  return (
    <div className={'tabs-wrapper'}>
      <TabGroup
        name='entitlement-view-tabs'
        activeId={activeTab}
        onChange={(e) => setActiveTab(e.target.id)}
        className='tabs'
      >
        {tabs.map((tab) => (
          <Tab key={tab} id={tab.id}>
            {tab.label}
          </Tab>
        ))}
      </TabGroup>
      {activeTab === 'policy' ? (
        <>
          {isEntitlementPending ? (
            <Spinner className='spinner' />
          ) : (
            <PolicyViewer style={{ fontSize: '14px' }} entitlement={entitlement} />
          )}
        </>
      ) : (
        <>
          {isAuditPending ? (
            <Spinner className='spinner' />
          ) : (
            <AuditLog audits={auditPager.items} />
          )}
        </>
      )}
    </div>
  )
}

export class EntitlementViewContent extends Component {
  componentDidMount() {
    let entitlementId = this.props.entitlementId

    Promise.all([this.props.getEntitlement(entitlementId), this.updateAudit()]).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error mounting EntitlementViewPage', error)
    })
  }

  updateAudit = () => {
    let entitlementId = this.props.entitlementId
    return this.props.getEntitlementAudits(entitlementId)
  }

  render() {
    const { lastSearchResult, isProduction } = this.props
    const searchUrl =
      (isProduction ? '/search' : '/preprod/search') +
      (lastSearchResult ? `?query=${lastSearchResult}` : '')

    return (
      <div>
        <PageTitle title='View Entitlement - Entitlements Dashboard' />
        <PageHeader
          title='Entitlement'
          back={
            <LinkButton
              beforeSlot={<Icon name='CaretLeft' />}
              label='Back to search'
              to={searchUrl}
              className='page-title-back'
            />
          }
        />
        <EntitlementView {...this.props} />
      </div>
    )
  }
}

export function EntitlementViewPage(props) {
  const { entitlementId } = useParams()
  return <EntitlementViewContent {...props} entitlementId={entitlementId} />
}

export default connect(
  (state) => {
    return {
      entitlement: selectEntitlement(state),
      auditPager: selectAuditPager(state),
      isEntitlementPending: selectIsRequestPending(state, getEntitlement),
      isAuditPending: selectIsRequestPending(state, getEntitlementAudits),
      requestError: selectRequestError(state, getEntitlement),
      lastSearchResult: selectLastSearchResult(state),
      isProduction: selectIsProduction(state),
    }
  },
  { getEntitlement, getEntitlementAudits }
)(EntitlementViewPage)
