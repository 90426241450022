import React, { Component } from 'react'
import { withFormik } from 'formik'
import { ActionInput } from '../components/index.js'

export class TransactionForm extends Component {
  componentDidMount() {
    const { traceId, submitForm } = this.props
    if (traceId) submitForm()
  }

  render() {
    const { values, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit} className='flex-container start mv3-sm'>
        <div className={errors.traceId ? 'flex-fill error' : 'flex-fill'}>
          <ActionInput
            id='traceId'
            aria-label='Search'
            placeholder='Enter a trace ID'
            type='text'
            value={values.traceId}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            errorMessage={errors.traceId}
            onAction={handleSubmit}
          />
        </div>
      </form>
    )
  }
}

export const formikConfig = {
  mapPropsToValues: (props) => {
    return { traceId: props.traceId || '' }
  },
  displayName: 'TransactionForm',
  handleSubmit: async (values, { props, setSubmitting }) => {
    await props.onSubmit(values).catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e)
    })
    setSubmitting(false)
  },
  validate: (values) => {
    let errors = {}
    if (!values.traceId || values.traceId.length === 0) {
      errors.traceId = 'Trace ID is required'
    }
    return errors
  },
}

export default withFormik(formikConfig)(TransactionForm)
