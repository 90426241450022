import join from 'url-join'
import debounce from 'p-debounce'
import { asyncAction } from '@nike/redux-action-utils'

import config from '../config.js'
import { http } from '../util/index.js'

const apiUrl = join.bind(join, config.apiUrl, 'v1')

const debouncedGetHealth = debounce(({ action, dispatch, environment, newVersion }) => {
  let params = {}
  if (environment) params.env = environment
  if (newVersion) params.newVersion = true
  dispatch(action.start())

  return http
    .get(apiUrl('health-check'), params)
    .then((response) => {
      dispatch(action.success(response))
    })
    .catch((error) => {
      dispatch(action.error(error))
    })
}, 100)

export const getHealth = asyncAction({
  baseName: 'HEALTH_CHECK_GET',
  // This action is called from multiple, independent components
  // Don't hit the API until they settle
  action:
    (action) =>
    ({ environment, newVersion } = {}) =>
    (dispatch) =>
      debouncedGetHealth({ action, dispatch, environment, newVersion }),
})
