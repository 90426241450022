import React, { Component } from 'react'
import hljs from 'highlightjs'

export default class JsonHighlighter extends Component {
  componentDidUpdate() {
    this.highlightPolicy()
  }

  componentDidMount() {
    if (this.props.object && this.codeBlock) {
      hljs.highlightBlock(this.codeBlock)
    }
  }

  highlightPolicy() {
    if (this.props.object && this.codeBlock) {
      hljs.highlightBlock(this.codeBlock)
    }
  }

  render() {
    const { object } = this.props

    if (!object) return null

    return (
      <pre
        ref={(node) => {
          this.codeBlock = node
        }}
      >
        <code>{JSON.stringify(object, null, 4)}</code>
      </pre>
    )
  }
}
