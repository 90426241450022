import React from 'react'
import { createPortal } from 'react-dom'
import { statusEmoji } from '../enums.js'

export function ToolTip({ status, healthCheckFrom, checks, x, y }) {
  const style = {
    opacity: 1,
    left: `${x + 10}px`,
    top: `${y + 25}px`,
  }

  const sumStatusVals = (serviceItem) => {
    if (serviceItem.some(({ status }) => status === 'fail')) return 'fail'
    if (serviceItem.some(({ status }) => status === 'warn')) return 'warn'
    if (serviceItem.some(({ status }) => status === 'pass')) return 'pass'
    return 'fail'
  }

  return (
    <div className='tooltip' style={style}>
      <span>
        <b>Traffic from:</b> {status.region}
      </span>
      <span>
        <b>Api location:</b> {healthCheckFrom}
      </span>
      {Object.keys(checks).map((serviceName) => {
        const serviceStatus = sumStatusVals(checks[serviceName])
        return (
          <span key={serviceName}>
            {statusEmoji[serviceStatus]} <b>{serviceName}</b>
          </span>
        )
      })}
    </div>
  )
}

const RegionTooltip = (props) =>
  createPortal(<ToolTip {...props} />, document.getElementById('app-host'))
export default RegionTooltip
