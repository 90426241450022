import React from 'react'
import { Spinner } from '@nike/eds'

const PageSpinner = () => (
  <div className='page-spinner'>
    <Spinner size='large' />
  </div>
)

export default PageSpinner
