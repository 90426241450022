import React, { Component } from 'react'
import { Provider } from 'react-redux'
import App from '../root/App.js'
import { BrowserRouter } from 'react-router-dom'

import config from '../config.js'
import configureStore from './store.js'

export const store = configureStore()

export default class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={config.basePath}>
          <App />
        </BrowserRouter>
      </Provider>
    )
  }
}
