import { combineReducers } from 'redux'

import { parseErrorMiddleware } from '@nike/redux-action-utils'
import navigation from '../navigation/reducer.js'
import offline from '../offline/reducer.js'
import request from '../util/requestReducer.js'
import notifications from '../notifications/reducer.js'
import entitlements from '../entitlements/reducer.js'
import health from '../health/reducer.js'
import {
  evaluationsReducer,
  statusCodeReducer,
  invokeDurationsReducer,
} from '../metrics/reducer.js'
import search from '../search/reducer.js'
import transactions from '../transactions/reducer.js'

export default combineReducers({
  request,
  parseErrorMiddleware,
  navigation,
  offline,
  notifications,
  entitlements,
  health,
  evaluations: evaluationsReducer,
  statusCodes: statusCodeReducer,
  invokeDurations: invokeDurationsReducer,
  search,
  transactions,
})
