import React from 'react'
import { withFormik } from 'formik'

import { Select } from '@nike/eds'
import { environments, dimensions } from '../enums.js'
import { Button, ActionInput } from '../components/index.js'

const filterKeyOptions = [
  { value: dimensions.namespace, label: 'Namespace' },
  { value: dimensions.owner, label: 'Owner' },
  { value: dimensions.entitlementId, label: 'Entitlement ID' },
]

const MetricsForm = (props) => {
  const { values, dirty, handleChange, handleSubmit, setFieldValue, isSubmitting, resetForm } =
    props

  const reset = () => {
    resetForm()
    handleSubmit()
  }

  const onSelect = (o) => {
    if (!o) return reset()
    setFieldValue('filterKey', o)
  }

  if (values && values.filterKey) {
    let label = filterKeyOptions.find((m) => m.value === values.filterKey)
    values.filterKey = label || values.filterKey
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='row mt-2'>
        <div className='form-group col-4'>
          <Select
            id='filterKey'
            options={filterKeyOptions}
            onChange={onSelect}
            value={values.filterKey}
            placeholder='Filter metrics by'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            isClearable
            hideLabel
          />
        </div>
        <div className='form-group col-6'>
          <ActionInput
            id='filterValue'
            placeholder='Enter a value'
            value={values.filterValue}
            disabled={values.filterKey === ''}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            onAction={handleSubmit}
          />
        </div>
        <div className='form-group col-2'>
          <Button
            variant={'secondary'}
            size='medium'
            disabled={!dirty || isSubmitting}
            onClick={reset}
          >
            Clear
          </Button>
        </div>
      </div>
    </form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    filterKey: '',
    filterValue: '',
    environment: environments.prod,
  }),

  handleSubmit: async (values, { props, setSubmitting }) => {
    if (values && values.filterKey) values.filterKey = values.filterKey.value
    await props.onSubmit(values).catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e)
    })
    setSubmitting(false)
  },

  displayName: 'MetricsForm',
})(MetricsForm)
