import React from 'react'
import Pager, { usePager } from '../components/Pager.js'
import PropTypes from 'prop-types'
import AuditItem from './AuditItem.js'

AuditLog.propTypes = {
  audits: PropTypes.array,
}

AuditLog.defaultProps = {
  audits: [],
}

export default function AuditLog({ audits }) {
  const [pageAudits /* pageCount */, , pageCount, selectPage] = usePager({ items: audits })
  const pager = <Pager pageCount={pageCount} onChange={selectPage} />
  const showTopPager = pageCount > 1
  const showBottomPager = pageCount > 1 && pageAudits.length > 8
  return (
    <div>
      {audits.length === 0 ? (
        <span className='u-full-width text-align-center fs20-sm'>No Audit Entries found.</span>
      ) : (
        <div>
          {showTopPager ? pager : null}
          {pageAudits.map((audit) => (
            <AuditItem key={audit.id} audit={audit} />
          ))}
          {showBottomPager ? pager : null}
        </div>
      )}
    </div>
  )
}
