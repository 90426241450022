import join from 'url-join'
import { asyncAction } from '@nike/redux-action-utils'

import { http } from '../util/index.js'
import { selectEnvironmentUrl } from '../navigation/reducer.js'

const api = (baseUrl, ...parts) => join(baseUrl, 'v2', ...parts)

export const getEntitlement = asyncAction({
  baseName: 'ENTITLEMENT_GET',
  action: (action) => (entitlementId) => (dispatch, getState) => {
    dispatch(action.start(entitlementId))
    let baseUrl = selectEnvironmentUrl(getState())
    return http
      .get(api(baseUrl, 'entitlements', entitlementId))
      .then((entitlement) => {
        dispatch(action.success(entitlement))
      })
      .catch((error) => {
        dispatch(action.error(error))
      })
  },
})

export const getEntitlementAudits = asyncAction({
  baseName: 'ENTITLEMENT_AUDIT_GET',
  action:
    (action) =>
    (entitlementId, { from = 0, pageSize = 50 } = {}) =>
    (dispatch, getState) => {
      dispatch(action.start(entitlementId))
      let baseUrl = selectEnvironmentUrl(getState())
      return http
        .get(api(baseUrl, 'entitlements', entitlementId, 'audits'))
        .then((response) => {
          dispatch(
            action.success({
              from,
              pageSize,
              items: response.items.sort((a, b) => {
                return b.createdOn - a.createdOn
              }),
              totalItems: response.totalItems,
            })
          )
        })
        .catch((error) => {
          dispatch(action.error(error))
        })
    },
})
