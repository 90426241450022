import React from 'react'

const Container = ({ children, fluid, section, extraClassName, className, style }) => {
  let containerClass = ['container', className || '']
  if (extraClassName) containerClass.push(extraClassName)
  if (fluid) containerClass.push('fixed-fluid')
  if (section) containerClass.push('bg-white border-light-grey p6-sm mb5-sm')

  return (
    <div className={containerClass.join(' ')} style={style}>
      {children}
    </div>
  )
}

export default Container
