import { useState } from 'react'
import { useSpring } from 'react-spring'

export function useSpringLoop(config) {
  const [reset, setReset] = useState(false)
  return useSpring({
    reset,
    onStart: () => setReset(false),
    onRest: () => setReset(true),
    ...config,
  })
}
