import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Text, Spinner } from '@nike/eds'
import { requireLogin } from '../auth/index.js'
import {
  StackedBarChart,
  StackedAreaChart,
  TimeSeriesChart,
  Container,
  PageTitle,
} from '../components/index.js'
import MetricsForm from './MetricsForm.js'
import { getEvaluations, getStatusCodes, getInvokeDurations } from './actions.js'
import { selectEnvironment } from '../navigation/reducer.js'
import HealthDisplay from '../health/HealthDisplay.js'
import HealthCheckGrid from '../health/HealthCheckGrid.js'
import EnvSwitch from '../navigation/EnvSwitch.js'

const EvaluationsChart = ({ data }) => {
  return <StackedBarChart data={data} yLabel={'Total Count'} />
}

const StatusCodesChart = ({ data }) => {
  return <StackedAreaChart data={data} yLabel='Total Count' />
}

const InvokeDurationsChart = ({ data }) => {
  return (
    <TimeSeriesChart
      data={data}
      tooltipValue={(v) => v.toFixed(2)}
      unit={'ms'}
      yLabel={'Time (ms)'}
    />
  )
}

function renderChart(ChartComponent, data) {
  if (data.length === 0 || Object.keys(data).length === 0) {
    return <Spinner className='spinner' />
  }

  return <ChartComponent data={data} />
}

class MetricsPage extends Component {
  componentDidMount() {
    this.getData({ environment: this.props.environment })
  }

  componentDidUpdate(previousProps) {
    const { environment } = this.props
    if (previousProps.environment !== environment) {
      this.getData({ environment })
    }
  }

  handleSubmit = ({ filterKey, filterValue }) => {
    this.getData({
      filterKey,
      filterValue,
      environment: this.props.environment,
    })
    return Promise.resolve()
  }

  getData(params) {
    this.props.getInvokeDurations(params)
    this.props.getStatusCodes(params)
    this.props.getEvaluations(params)
  }

  render() {
    const { evaluations, statusCodes, invokeDurations, isProduction } = this.props

    return (
      <div>
        <PageTitle title='View Metrics - Entitlements Dashboard' />
        <Container className='mb-2 metrics-header'>
          <div className='mb-2'>
            <EnvSwitch isProduction={isProduction} />
          </div>
          <HealthDisplay />
        </Container>
        <Container section className={'section-border metrics-page row'}>
          <div className={'ml-2 mr-2'}>
            <div className='mb-2'>
              <MetricsForm onSubmit={this.handleSubmit} />
            </div>
            <div className='metrics-grid'>
              <div className='chart-container evaluations-chart'>
                <Text as='h4' font='subtitle-1' className='mb-2'>
                  Evaluations
                </Text>
                {renderChart(EvaluationsChart, evaluations)}
              </div>
              <div className='chart-container status-codes-chart'>
                <Text as='h4' font='subtitle-1' className='mb-2'>
                  Status Codes
                </Text>
                {renderChart(StatusCodesChart, statusCodes)}
              </div>
              <div className='chart-container invoke-duration-chart'>
                <Text as='h4' font='subtitle-1' className='mb-2'>
                  Invoke Duration (global)
                </Text>
                {renderChart(InvokeDurationsChart, invokeDurations)}
              </div>
              <div className='chart-container regional-status-chart'>
                <Text as='h4' font='subtitle-1' className='mb-2'>
                  Regional Status
                </Text>
                <HealthCheckGrid />
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default compose(
  requireLogin('Metrics'),
  connect(
    (state) => {
      return {
        evaluations: state.evaluations.evaluations,
        statusCodes: state.statusCodes.statusCodes,
        invokeDurations: state.invokeDurations.invokeDurations,
        environment: selectEnvironment(state),
        isProduction: state.navigation.get('isProduction'),
      }
    },
    { getEvaluations, getStatusCodes, getInvokeDurations }
  )
)(MetricsPage)
