import React from 'react'
import { Route, Routes } from 'react-router-dom'

import HomePage from '../root/HomePage.js'
import MetricsPage from '../metrics/MetricsPage.js'
import EntitlementViewPage from '../entitlements/EntitlementViewPage.js'
import SearchPage from '../search/SearchPage.js'
import TransactionPage from '../transactions/TransactionPage.js'
import { NotFound } from '../components/index.js'

export default function AppRoutes() {
  return (
    <Routes>
      <Route exact path='/' element={<HomePage />} />
      <Route path='/metrics' element={<MetricsPage />} />
      <Route path='/preprod/metrics' element={<MetricsPage />} />
      <Route path='/entitlements/:entitlementId' element={<EntitlementViewPage />} />
      <Route path='/search' element={<SearchPage />} />
      <Route path='/preprod/search' element={<SearchPage />} />
      <Route path='/transactions' element={<TransactionPage />} />
      <Route path='/preprod/transactions' element={<TransactionPage />} />
      <Route path='/transactions/:traceId' element={<TransactionPage />} />
      <Route path='/preprod/transactions/:traceId' element={<TransactionPage />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
