/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import deepDiff from 'deep-diff'
import moment from 'moment'
import stringify from 'json-stable-stringify'
import { Icon, Text } from '@nike/eds'
import { JsonTooltip, Collapsible, Diff } from '../components/index.js'

const diffMap = {
  N: 'Added',
  D: 'Removed',
  E: 'Changed',
  // 'A': 'Child Changed' // Just for reference, don't use this
}

export default function AuditItem({ audit }) {
  const { username, createdOn, original, modified } = audit
  return (
    <div className='card' style={{ fontSize: '14px' }}>
      <div>
        <div>
          <Text font='subtitle-1'>User</Text>
          <Text font='body-3'>{username}</Text>
        </div>

        <div>
          <Text font='subtitle-1'>Created</Text>{' '}
          <Text font='body-3'>{moment(createdOn).format('YYYY-MM-DD hh:mmA')}</Text>
        </div>

        <div>
          <Text font='subtitle-1'>Changes</Text>{' '}
          <Text font='body-3' as='div'>
            <AuditChanges original={original} modified={modified} />
          </Text>
        </div>

        <Collapsible
          trigger={
            <div className={'mb-quarter audit-collapse'}>
              <a className='text-accent'>See Full Diff</a>
              <Icon name='CaretDown' className='collapse-arrow' />
            </div>
          }
        >
          <Diff left={asDiffString(audit.original)} right={asDiffString(audit.modified)} />
        </Collapsible>
      </div>
    </div>
  )
}

function asDiffString(diff) {
  // stable json is needed to stop false-positive-diffs
  // from properties moving around
  return stringify(diff, { space: 2 })
}

const datesToFilter = ['createdAtISO', 'lastUpdatedAtISO', 'lastUpdatedAt']
function AuditChanges({ original, modified }) {
  let message
  let diff = deepDiff(original, modified).filter(
    (d) => !d.path || !(d.path.length === 1 && datesToFilter.includes(d.path[0]))
  )
  if (!diff.length) {
    message = <span key={1}> No Changes</span>
  } else if (diff.length === 1 && diff[0].kind === 'N' && !diff[0].path) {
    message = <span key={1}> Created</span>
  } else {
    message = diff.map(diffLabel)
  }
  return message
}

function diffLabel(diff, key) {
  if (diff.kind === 'A') {
    // Array change, append path and recurse
    return diffLabel({ ...diff.item, path: [...diff.path, diff.index] }, key + diff.path)
  }
  let action = diffMap[diff.kind]
  let path = diff.path.join('.')
  // Adds and removes are one-handed (left or right handed)
  if (diff.kind === 'N' && typeof diff.rhs === 'object')
    return (
      <div key={key}>
        {action}{' '}
        <JsonTooltip object={diff.rhs}>
          <a className='text-accent'>{path}</a>
        </JsonTooltip>
      </div>
    )
  if (diff.kind === 'D' && typeof diff.lhs === 'object')
    return (
      <div key={key}>
        {action}{' '}
        <JsonTooltip object={diff.lhs}>
          <a className='text-accent'>{path}</a>
        </JsonTooltip>
      </div>
    )
  let label = (
    <span>
      {action} <span className='bg-light-grey p1'>{path}</span>
    </span>
  )
  let value
  if (diff.kind === 'N' && typeof diff.rhs !== 'boolean')
    value = <span> as {formatDiffValue(diff.rhs)}</span>
  else if (diff.kind !== 'N' && diff.kind !== 'D')
    value = (
      <span>
        {' '}
        from {formatDiffValue(diff.lhs)} to {formatDiffValue(diff.rhs)}
      </span>
    )
  else if (diff.kind === 'D' && typeof diff.lhs !== 'boolean')
    value = <span> was {formatDiffValue(diff.lhs)}</span>
  return (
    <div key={key} style={{ marginTop: '2px' }}>
      {label}
      {value}
    </div>
  )
}

function formatDiffValue(diff) {
  return typeof diff === 'object' ? (
    <JsonTooltip object={diff}>
      <a className='text-accent'>see resource</a>
    </JsonTooltip>
  ) : (
    <span className='text-accent'>{diff}</span>
  )
}
