import React, { useState, useEffect, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { Icon } from '@nike/eds'

export default function Pager({ pageCount, onChange, range = 5 }) {
  return (
    <div className={'mb-1'}>
      <ReactPaginate
        previousLabel={<Icon name='CaretLeft' size='s' />}
        nextLabel={<Icon name='CaretRight' size='s' />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={range}
        onPageChange={({ selected }) => onChange(selected)}
        containerClassName={'react-paginate pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  )
}

export function usePager({ items, pageSize = 20 }) {
  // Use a single value since they are updated atomically
  const [{ pageCount, pageItems, pageIndex }, setState] = useState({
    // Calculate initial number of pages based on perPage value
    pageCount: items.length / pageSize,
    pageIndex: 0,
    pageItems: [],
  })
  const selectPage = useCallback(
    (pageIndex) => {
      const offset = Math.ceil(pageIndex * pageSize)
      const pageItems = items.slice(offset, pageSize + offset)

      setState({ pageItems, pageSize, pageIndex, pageCount: items.length / pageSize })
    },
    [items, pageSize]
  )
  // Run on mount, or when props/callback change
  // but do not re-run when pageIndex changes
  // Since that can only be changed from the selectPage callback
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => selectPage(pageIndex), [items, pageSize])
  return [pageItems, pageIndex, pageCount, selectPage]
}
