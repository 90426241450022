import React from 'react'
import { TextField, Icon, IconButton } from '@nike/eds'

const ActionInput = ({ onClear, disabled, errorMessage, onAction, ...props }) => {
  return (
    <TextField
      {...props}
      hideLabel
      beforeSlot={
        onAction ? (
          <IconButton
            variant='ghost'
            icon='Search'
            size='medium'
            disabled={disabled}
            onClick={onAction}
          />
        ) : (
          <Icon className='action-search' name='Search' size='m' />
        )
      }
      disabled={disabled}
      afterSlot={onClear ? <Icon name='Close' size='m' onClick={!disabled && onClear} /> : null}
      errorMessage={errorMessage}
      hasErrors={!!errorMessage}
    />
  )
}

export default ActionInput
