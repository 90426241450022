import React from 'react'
import { HomePage as PlatformHomePage } from '@cx/tech-solution-homepage'
import { selectAccessToken, useAuthStore } from '@nike/aegis-auth-react'

import config from '../config.js'

export default function HomePage() {
  const accessToken = useAuthStore(selectAccessToken)
  return <PlatformHomePage accessToken={accessToken} techSolutionId={config.platformConsoleId} />
}
