import React from 'react'
import Tip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'

const Tooltip = ({
  hover,
  click,
  focus,
  trigger,
  placement = 'right',
  content,
  children,
  hideAfter = 0,
  inline,
}) => {
  if ((hover || click || focus) && trigger)
    throw new Error(
      'trigger cannot be used with click, hover, and focus. trigger is the long-form of those options'
    )
  if (!trigger) {
    trigger = []
    if (click) trigger.push('click')
    if (hover) trigger.push('hover')
    if (focus) trigger.push('focus')
    if (!trigger.length) trigger.push('hover')
  }
  return (
    <Tip placement={placement} trigger={trigger} overlay={content} mouseLeaveDelay={hideAfter}>
      <div className={inline ? 'd-sm-ib' : ''}>{children}</div>
    </Tip>
  )
}

export default Tooltip
