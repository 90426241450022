import { createBrowserHistory } from 'history'
import qs from 'qs'

let basename = '/entitlements'
const _history = createBrowserHistory({ basename })

function addLocationQuery(history) {
  if (!history.location.search) return
  history.location = Object.assign(history.location, {
    query: qs.parse(history.location.search.substring(1)),
  })
}

addLocationQuery(_history)

_history.listen(() => {
  addLocationQuery(_history)
})

_history.getMergedQueryString = (obj) => {
  const search = qs.parse(_history.location.search, {
    ignoreQueryPrefix: true,
  })
  const nextSearch = { ...search, ...obj }
  return qs.stringify(nextSearch)
}

_history.mergeQueryString = (obj) => {
  _history.push({ search: _history.getMergedQueryString(obj) })
}

export default _history
