import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, TabGroup, Tab, Text, Spinner } from '@nike/eds'

import { clearTransaction, getTransaction } from './actions.js'
import TransactionForm from './TransactionForm.js'
import { selectEnvironment } from '../navigation/reducer.js'
import { environments, logLevels } from '../enums.js'

import EnvSwitch from '../navigation/EnvSwitch.js'
import { Collapsible, Slider, Container, PageTitle } from '../components/index.js'
import { selectIsRequestPending, selectRequestError, history, spliceArray } from '../util/index.js'
import { useParams } from 'react-router-dom'

const LogLabel = ({ timestamp, severity }) => (
  <div className={'transaction-log-label'}>
    <em>{timestamp}</em>
    <Text font='subtitle-1' as='span' className='severity'>
      {severity}
    </Text>
    <Icon name='CaretDown' className='collapse-arrow' />
  </div>
)

const Log = ({ logSource, open, handleTriggerClick }) => (
  <div className='transaction-log section-border'>
    <Collapsible
      trigger={<LogLabel {...logSource} />}
      open={open}
      handleTriggerClick={() => handleTriggerClick(logSource.traceIndex)}
    >
      <Text font='body-3' as='pre'>
        {logSource.message}
      </Text>
    </Collapsible>
  </div>
)

const collapseToggleOptions = [
  { label: 'Expanded', value: 'expanded' },
  { label: 'Collapsed', value: 'collapsed' },
]

const marks = logLevels.reduce((result, logLevel, i) => {
  result[i] = logLevel
  return result
}, {})

class TransactionContent extends Component {
  state = {
    toggleOption: 'expanded',
    expandedLogs: [],
    logLevel: 2,
  }

  componentDidUpdate() {
    const { logs } = this.props
    if (this.state.expandedLogs.length === 0 && logs && logs.length > 0) {
      this.setState({
        expandedLogs: logs.filter(this.isWithinSeverityRange).map(() => true),
      })
    }
  }

  componentWillUnmount() {
    this.props.clearTransaction()
  }

  handleCollapseToggleChange = (value) => {
    const isExpanded = value === 'expanded'
    this.setState({
      expandedLogs: this.state.expandedLogs.map(() => isExpanded),
      toggleOption: value,
    })
  }

  handleCollapseTriggerClick = (logIndex) => () => {
    this.setState({
      expandedLogs: spliceArray(
        this.state.expandedLogs,
        logIndex,
        !this.state.expandedLogs[logIndex]
      ),
    })
  }

  handleSubmit = ({ traceId }) => {
    this.props.clearTransaction()
    if (!traceId) traceId = this.props.traceId
    const newPath =
      this.props.environment === environments.preprod
        ? `/preprod/transactions/${traceId}`
        : `/transactions/${traceId}`
    history.push(newPath)
    return this.props.getTransaction({ traceId })
  }

  handleLogLevelChange = (logLevel) => {
    this.setState({ logLevel })
  }

  isWithinSeverityRange = (log) =>
    logLevels
      .slice(0, this.state.logLevel + 1)
      .some((logLevel) => logLevel === log._source.severity)

  renderLog = (log, i) => (
    <Log
      key={log._id}
      logSource={log._source}
      open={this.state.expandedLogs[i]}
      handleTriggerClick={this.handleCollapseTriggerClick(i)}
    />
  )

  renderTransaction = () => {
    const { isTransactionPending, logs } = this.props

    if (!isTransactionPending && !logs) return
    if (isTransactionPending || !logs) return <Spinner size='large' className='center mt-2 mb-2' />
    if (logs.length === 0) {
      return '🙁 We could not find any logs with the supplied ID'
    }

    const { appName, traceId, path } = logs[0]._source
    const filteredLogs = logs.filter(this.isWithinSeverityRange)

    return (
      <Container section className={'section-border mt-2'}>
        <PageTitle title='View Transaction - Entitlements Dashboard' />
        <div className='transaction-header mt-2'>
          <ol>
            <li className={'mb-half'}>
              <Text font='subtitle-2' as='span'>
                Transaction ID:
              </Text>{' '}
              {traceId}
            </li>
            <li className={'mb-half'}>
              <Text font='subtitle-2' as='span'>
                Environment:
              </Text>{' '}
              {formatEnvironment(appName)}
            </li>
            <li className={'mb-half'}>
              <Text font='subtitle-2' as='span'>
                Path:
              </Text>{' '}
              {path}
            </li>
          </ol>
          <div style={{ width: '30%' }}>
            <div className='mb-2'>
              <Text font='subtitle-2' as='span'>
                Log Level
              </Text>
            </div>
            <Slider
              min={0}
              max={3}
              value={this.state.logLevel}
              onChange={this.handleLogLevelChange}
              marks={marks}
            />
          </div>
          <div style={{ alignSelf: 'start' }}>
            <TabGroup
              name='expanded-select'
              activeId={this.state.toggleOption}
              onChange={(e) => {
                this.handleCollapseToggleChange(e.target.id)
              }}
              variant='toggle'
            >
              {collapseToggleOptions.map((tab) => (
                <Tab key={tab} id={tab.value}>
                  {tab.label}
                </Tab>
              ))}
            </TabGroup>
          </div>
        </div>
        <div>
          {filteredLogs.length > 0
            ? filteredLogs.map(this.renderLog)
            : '🚫 No logs match the current log level settings'}
        </div>
      </Container>
    )
  }

  render() {
    const { isProduction } = this.props

    return (
      <Container>
        <EnvSwitch isProduction={isProduction} />
        <TransactionForm traceId={this.props.traceId} onSubmit={this.handleSubmit} />
        {this.renderTransaction()}
      </Container>
    )
  }
}

function formatEnvironment(appName) {
  if (appName.includes('dev')) return 'Development'
  else if (appName.includes('preprod')) return 'Pre-production'
  return 'Production'
}

const mapStateToProps = (state) => ({
  logs: state.transactions.logs,
  isTransactionPending: selectIsRequestPending(state, getTransaction),
  requestError: selectRequestError(state, getTransaction),
  traceId: state.transactions.traceId,
  environment: selectEnvironment(state),
  isProduction: state.navigation.get('isProduction'),
})

export function TransactionPage(props) {
  const { traceId } = useParams()
  return <TransactionContent {...props} traceId={traceId} />
}

export default connect(mapStateToProps, {
  clearTransaction,
  getTransaction,
})(TransactionPage)
