import React from 'react'

import Tooltip from '../components/Tooltip.js'

const JsonTooltip = ({ object, children }) => (
  <Tooltip inline content={<pre>{`${JSON.stringify(object, null, 2)}`}</pre>} placement='top'>
    {children}
  </Tooltip>
)

export default JsonTooltip
