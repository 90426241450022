import merge from 'deepmerge'

const basePath = '/entitlements'

const base = {
  appName: 'Entitlements',
  appFullName: 'Entitlements',
  slack: {
    teamId: 'T0XT0BQ3V',
    channelId: 'CB1J26T5E',
  },
  basePath,
  oAuth: {
    autoRenew: true,
    clientId: 'nike.cx.entitlements-dashboard',
    redirectUri: window.location.origin + basePath,
    responseType: 'id_token token',
    scope: 'profile openid email offline_access',
    // storageNamespace: 'entitlements',
  },
  entitlementApis: {
    preprod: 'https://api-entitlements.preprod.niketech.com',
    prod: 'https://api-entitlements.niketech.com',
  },
  platformConsoleId: 'caa4dbdc-691f-4acd-b25d-719b60c3fca4',
  platformConsoleEnv: 'dev',
}

const dev = merge(base, {
  apiUrl: 'https://api-entitlements-dashboard-dev.lab.niketech.com',
  oAuth: {
    authorization: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize',
  },
  entitlementApis: {
    preprod: 'https://api-entitlements-dev.lab.niketech.com',
    prod: 'https://api-entitlements.preprod.niketech.com',
  },
})

const preprod = merge(base, {
  apiUrl: 'https://api-entitlements-dashboard.preprod.niketech.com',
  oAuth: {
    authorization: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize',
  },
  entitlementApis: {
    preprod: 'https://api-entitlements-dev.lab.niketech.com',
    prod: 'https://api-entitlements.preprod.niketech.com',
  },
  platformConsoleEnv: 'nonprod',
})

const prod = merge(base, {
  isProd: true,
  apiUrl: 'https://api-entitlements-dashboard.niketech.com',
  oAuth: {
    authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize',
  },
  platformConsoleEnv: 'prod',
})

// eslint-disable-next-line import/no-mutable-exports
let config

if (window.location.origin.includes('//console.platforms.nike.com')) {
  config = prod
} else if (window.location.origin.includes('//console-nonprod.platforms.nike.com')) {
  config = preprod
} else {
  config = dev
}

export default config
