import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import urlJoin from 'url-join'

import { selectIsProduction } from './reducer.js'

export function useEnvBasePathJoiner() {
  const isProduction = useSelector(selectIsProduction)
  return useCallback(
    (path) => {
      return isProduction ? path : urlJoin('/preprod', path)
    },
    [isProduction]
  )
}
