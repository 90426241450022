export const dimensions = {
  namespace: 'namespace',
  owner: 'owner',
  entitlementId: 'entitlementId',
  traceId: 'traceId',
}

export const environments = {
  prod: 'prod',
  preprod: 'preprod',
}

export const logLevels = ['ERROR', 'WARN', 'INFO', 'DEBUG']

export const colors = {
  red: 'rgb(220, 53, 69)',
  yellow: 'rgb(255, 193, 7)',
  green: 'rgb(40, 167, 69)',
}

export const statusEmoji = {
  pass: '✅',
  warn: '⚠️',
  fail: '💩',
}
