import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text, Icon } from '@nike/eds'

import { getHealth } from './actions.js'
import { selectGlobalStatus } from './reducer.js'

import { environments } from '../enums.js'

export default function HealthDisplay() {
  const dispatch = useDispatch()
  let isProduction = useSelector((state) => state.navigation.get('isProduction'))
  let operational = useSelector(selectGlobalStatus)

  useEffect(() => {
    let action = getHealth({
      environment: isProduction ? environments.prod : environments.preprod,
    })

    dispatch(action)
  }, [dispatch, isProduction])

  return (
    <Text font={'title-5'} className='health-container md mb-2'>
      <span className='status'>Status:</span>
      <Status status={operational} iconSize={'large'} />
    </Text>
  )
}

function Status({ status, iconSize }) {
  switch (status) {
    case 'success':
      return (
        <div className='health-indicator'>
          Operational
          <Icon
            name='CheckCircleFilled'
            color={'var(--eds-color-background-success)'}
            size={iconSize}
          />
        </div>
      )
    case 'warn':
      return (
        <div className='health-indicator'>
          Warning
          <Icon
            name='AlertCircleFilled'
            color={'var(--eds-color-background-warning)'}
            iconSize={iconSize}
          />
        </div>
      )
    case 'fail':
      return (
        <div className='health-indicator'>
          Fail
          <Icon
            name='AlertCircleFilled'
            color={'var(--eds-color-background-danger)'}
            iconSize={iconSize}
          />
        </div>
      )
    default:
      return 'Fetching ...'
  }
}
