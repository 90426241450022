import { createAction } from '@nike/redux-action-utils'
import { history } from '../util/index.js'

export const toggleEnvironment = createAction('TOGGLE_ENVIRONMENT', {
  noPayload: true,
})
export const historyChanged = createAction('HISTORY_CHANGED')

export const subscribeToHistory = (store) => {
  history.listen(() => {
    store.dispatch(historyChanged(history.location))
  })
}
