import React from 'react'
import moment from 'moment'
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import { standardizeTimeByHalfHour } from '../util/index.js'

const chartMargin = { top: 5, right: 0, bottom: 5, left: 5 }

const colorMap = {
  200: '#82ca9d',
  201: '#628c72',
  400: '#fc6201',
}

const processData = (data) => {
  let processedData = {
    keys: [],
    timeSeries: [],
  }

  let startDateTime = standardizeTimeByHalfHour(moment().subtract(24, 'hours'))

  for (let x = 0; x < data[0].timeSeries.length; x++) {
    let entry = {
      name: startDateTime
        .clone()
        .add(30 * x, 'minutes')
        .format('HH:mm'),
    }

    for (let y = 0; y < data.length; y++) {
      entry[data[y].name] = data[y].timeSeries[x]
    }

    processedData.timeSeries.push(entry)
  }

  processedData.keys = data.map((x) => {
    return {
      name: x.name,
      color: colorMap[x.name] || '#' + Math.floor(Math.random() * 16777215).toString(16),
      count: x.timeSeries.reduce((sum, v) => sum + v, 0),
    }
  })

  processedData.keys.sort((a, b) => (a.count < b.count ? 1 : -1))

  return processedData
}

export default function StackedAreaChart({ data, yLabel }) {
  if (!data) {
    return null
  }

  let processedData = processData(data)

  return (
    <ResponsiveContainer aspect={2.5}>
      <AreaChart data={processedData.timeSeries} margin={chartMargin} syncId='metrics'>
        <defs>
          {processedData.keys.map((x) => (
            <linearGradient id={`color${x.name}`} key={x.name} x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor={x.color} stopOpacity={0.8} />
              <stop offset='95%' stopColor={x.color} stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        <XAxis dataKey='name' interval='preserveStartEnd' />
        <YAxis label={{ value: yLabel, angle: -90, offset: 0, position: 'insideLeft' }} />
        <Tooltip />
        <Legend verticalAlign='bottom' height={36} />
        {processedData.keys.map((x) => (
          <Area
            type='monotone'
            dataKey={x.name}
            key={x.name}
            stackId='1'
            legendType='square'
            stroke={x.color}
            fillOpacity={1}
            fill={`url(#color${x.name})`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  )
}
