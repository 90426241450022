import { getEvaluations, getStatusCodes, getInvokeDurations } from './actions.js'

const defaultEvaluations = {
  evaluations: [],
}

const defaultStatusCodes = {
  statusCodes: [],
}

const defaultInvokeDurations = {
  invokeDurations: [],
}

export const evaluationsReducer = (state = defaultEvaluations, action) => {
  switch (action.type) {
    case getEvaluations.event.success:
      if (action.payload.length === 0) {
        return { ...state, allows: [], denies: [] }
      }

      return {
        evaluations: action.payload.data,
      }
    default:
      return state
  }
}

export const statusCodeReducer = (state = defaultStatusCodes, action) => {
  switch (action.type) {
    case getStatusCodes.event.success:
      return {
        statusCodes: action.payload.data,
      }
    default:
      return state
  }
}

export const invokeDurationsReducer = (state = defaultInvokeDurations, action) => {
  switch (action.type) {
    case getInvokeDurations.event.success:
      return {
        invokeDurations: action.payload.data,
      }
    default:
      return state
  }
}
