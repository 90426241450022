import React, { Component } from 'react'
import { default as ReactMarkdown } from 'react-markdown'

export default class Markdown extends Component {
  static defaultProps = {
    allowHtml: false,
  }
  render() {
    let { allowHtml } = this.props
    let renderers = this.props.renderers || {}
    return (
      <div className='markdown-container'>
        <ReactMarkdown
          source={this.props.source || ''}
          escapeHtml={!allowHtml}
          renderers={{ ...ReactMarkdown.renderers, ...renderers }}
        />
      </div>
    )
  }
}
