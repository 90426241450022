import React from 'react'
import { connect } from 'react-redux'
import { TransitionGroup } from 'react-transition-group'

import { removeNotification, notify, notifyError } from './actions.js'
import { selectNotifications } from './reducer.js'
import { RenderInBody } from '../components/index.js'

const formatNotifcation = (message) => {
  if (message instanceof Error) {
    return message.message
  }
  if (typeof message === 'object') {
    throw new Error('unable to format object as notification')
  }

  return message
}

const Toasts = ({ toasts, removeNotification }) => {
  toasts = toasts.toArray()
  return (
    <RenderInBody>
      <div className='alertify-logs bottom right'>
        {toasts.map((toast) => (
          <div
            key={toast.id}
            className={`${toast.type} alertify-log`}
            onClick={toast.closeOnClick ? () => removeNotification(toast.id) : undefined}
          >
            {formatNotifcation(toast.message)}
          </div>
        ))}
      </div>
    </RenderInBody>
  )
}

export default connect(
  (state) => ({
    toasts: selectNotifications(state),
  }),
  { removeNotification, notify, notifyError }
)(Toasts)
