import React from 'react'

import { Container, NotFound } from '../index.js'

const is404 = (error) =>
  error && ((error.status && error.status === 404) || (error.response && is404(error.response)))

const Generic = ({ error }) => {
  if (is404(error)) return <NotFound />
  return (
    <Container section>
      {error && <h2>An error occured, unable to load page.</h2> ? (
        <pre>{error.message || error}</pre>
      ) : null}
    </Container>
  )
}

export default Generic
