import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

import Root from './root/Root'
import configureStore from './root/store.js'
import { initAuthEvents } from './auth/events.js'
import { watchOnlineState } from './offline/actions.js'
import { ErrorBoundary } from './components/errors/ErrorBoundary.js'

import './stylus/app.styl'

export const store = configureStore()

// Track browser's online state
watchOnlineState(store)

// Auth client events
initAuthEvents()

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary: ErrorBoundary,
})

export const { bootstrap, mount, unmount } = lifecycles
