import { getHealth } from './actions.js'

const defaultRegionalStatus = {
  globalStatus: 'fetching',
  allStatus: [],
}

export default function health(state = defaultRegionalStatus, action) {
  switch (action.type) {
    case getHealth.event.success: {
      const {
        payload: { status, data: allStatus },
      } = action
      const globalStatus = status || 'fetching'
      return {
        globalStatus,
        allStatus,
      }
    }
    default:
      return state
  }
}

export const selectRegionalStatus = (state) => state.health.allStatus
export const selectGlobalStatus = (state) => state.health.globalStatus
