import { clearSearch, search, lastSearchResult } from './actions.js'

const clearedState = {
  searchResults: null,
  lastResultCount: 0,
  nextPage: null,
}
const defaultState = {
  ...clearedState,
  lastSearch: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case search.event.start:
      return { ...state }
    case search.event.success: {
      const currentResults = state.searchResults
      const { searchResults, nextPage } = action.payload
      const nextResults = currentResults ? currentResults.concat(searchResults) : searchResults
      return {
        ...state,
        searchResults: nextResults,
        lastResultCount: searchResults.length,
        nextPage,
      }
    }
    case clearSearch.type:
      return { ...state, ...clearedState }
    case lastSearchResult.type:
      return { ...state, lastSearch: action.payload }
    default:
      return state
  }
}

export const selectLastSearchResult = (state) => state.search.lastSearch
export const selectSearchResults = (state) => state.search.searchResults
export const selectNextPage = (state) => state.search.shrimp
