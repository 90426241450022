import React from 'react'
import { Text } from '@nike/eds'
import { Container } from '../components/index.js'
import classNames from 'classnames'

const PageHeader = ({ title, back, children }) => {
  let headerStyle = classNames('flex-fill', { 'page-title-has-back': back })
  return (
    <Container section extraClassName='flex-container start page-header'>
      <Text as='h2' font={'title-4'} className={headerStyle}>
        {title}
        {back || null}
      </Text>
      {React.Children.count(children) ? <div className='flex-static'>{children}</div> : null}
    </Container>
  )
}

export default PageHeader
