import { useEffect } from 'react'

import config from '../config'

const baseTitle = config.title

function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title
  }, [title])
}

function formatTitle(pageTitle) {
  return pageTitle ? `${pageTitle} - ${baseTitle}` : baseTitle
}

export default function PageTitle({ title, children }) {
  const pageTitle = formatTitle(title)
  useDocumentTitle(pageTitle)
  return <>{children}</>
}
