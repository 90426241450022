import React from 'react'
import moment from 'moment'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import { standardizeTimeByHalfHour } from '../util/index.js'

const chartMargin = { top: 5, right: 0, bottom: 5, left: 5 }

const colorMap = {
  Allow: '#82ca9d',
  Deny: '#8884d8',
  Actions: '#7fc7bf',
  Missing: '#fc6201',
}

const processData = (data) => {
  let processedData = {
    keys: [],
    timeSeries: [],
  }

  let startDateTime = standardizeTimeByHalfHour(moment().subtract(24, 'hours'))

  for (let x = 0; x < data[0].timeSeries.length; x++) {
    let entry = {
      name: startDateTime
        .clone()
        .add(30 * x, 'minutes')
        .format('HH:mm'),
    }

    for (let y = 0; y < data.length; y++) {
      entry[data[y].name] = data[y].timeSeries[x]
    }

    processedData.timeSeries.push(entry)
  }

  processedData.keys = data.map((x) => {
    return {
      name: x.name,
      color: colorMap[x.name] || '#' + Math.floor(Math.random() * 16777215).toString(16),
      count: x.timeSeries.reduce((sum, v) => sum + v, 0),
    }
  })

  processedData.keys.sort((a, b) => (a.count < b.count ? 1 : -1))

  return processedData
}

export default function StackedBarChart({ data, yLabel }) {
  if (!data) {
    return null
  }

  let processedData = processData(data)

  return (
    <ResponsiveContainer aspect={2.5}>
      <BarChart data={processedData.timeSeries} margin={chartMargin} syncId='metrics'>
        <XAxis dataKey='name' interval='preserveStartEnd' />
        <YAxis label={{ value: yLabel, angle: -90, offset: 0, position: 'insideLeft' }} />
        <Tooltip />
        <Legend
          verticalAlign='bottom'
          height={36}
          payload={processedData.keys
            .slice()
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((x) => {
              return { value: x.name, type: 'square', id: x.name, color: x.color }
            })}
        />
        {processedData.keys.map((x) => (
          <Bar dataKey={x.name} key={x.name} stackId='1' fill={x.color} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}
