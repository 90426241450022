import { fromJS } from 'immutable'

import { toggleEnvironment, historyChanged } from '../navigation/actions.js'
import { history } from '../util/index.js'
import { environments } from '../enums.js'
import config from '../config.js'

let defaultState = fromJS({
  isProduction: !isPreprodUrl(history.location),
})

export default function navigation(state = defaultState, action) {
  state = fromJS(state)

  switch (action.type) {
    case toggleEnvironment.type:
      return state.set('isProduction', !state.get('isProduction'))

    case historyChanged.type: {
      let isProduction = !isPreprodUrl(action.payload)
      return isProduction !== state.get('isProduction')
        ? state.set('isProduction', isProduction)
        : state
    }
    default:
      return state
  }
}

function isPreprodUrl(location) {
  return location.pathname.includes('/preprod')
}

export const selectEnvironment = () => {
  let isProduction = !history.location.pathname.includes('/preprod')
  return isProduction ? environments.prod : environments.preprod
}
export const selectEnvironmentUrl = (state) => config.entitlementApis[selectEnvironment(state)]
export const selectIsProduction = (state) => state.navigation.get('isProduction')
