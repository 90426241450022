import React, { Component } from 'react'
import { withFormik } from 'formik'
import { ActionInput } from '../components/index.js'

export class SearchForm extends Component {
  componentDidMount() {
    const { query, submitForm } = this.props
    if (query) submitForm()
  }

  render() {
    const { values, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit} className='flex-container start mb-1'>
        <div className={errors.query ? 'flex-fill error' : 'flex-fill'}>
          <ActionInput
            id='query'
            aria-label='Search'
            placeholder='Enter a search term'
            type='text'
            value={values.query}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            errorMessage={errors.query}
            onAction={handleSubmit}
          />
        </div>
      </form>
    )
  }
}

export const formikConfig = {
  mapPropsToValues: (props) => {
    return { query: props.query || '' }
  },
  displayName: 'SearchForm',
  handleSubmit: async (values, { props, setSubmitting }) => {
    props.onSubmit(values)
    setSubmitting(false)
  },
  validate: (values) => {
    let errors = {}
    if (!values.query || values.query.length === 0) {
      errors.query = 'Search term required'
    }
    return errors
  },
}

export default withFormik(formikConfig)(SearchForm)
