const defaultState = {}

const transactionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'TRANSACTION_GET_SUCCESS':
      return { logs: action.payload.items, traceId: action.payload.traceId }
    case 'TRANSACTION_GET_ERROR':
      return {
        logs: undefined,
        traceId: action.payload.traceId,
        error: action.payload.error,
      }
    case 'TRANSACTION_CLEAR':
      return { ...defaultState }
    default:
      return state
  }
}

export default transactionsReducer
