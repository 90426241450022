import join from 'url-join'
import { asyncAction, createAction } from '@nike/redux-action-utils'

import { http } from '../util/index.js'
import { selectEnvironmentUrl } from '../navigation/reducer.js'

export const search = asyncAction({
  baseName: 'SEARCH',
  action:
    (action) =>
    ({ query, nextPage }) =>
    (dispatch, getState) => {
      if (!query && !nextPage) return

      dispatch(action.start())

      const entitlementsUrl =
        nextPage || join(selectEnvironmentUrl(getState()), 'v2', 'entitlements')

      return http
        .get(entitlementsUrl, { query })
        .then((searchResults) => {
          const payload = { searchResults: searchResults.items }
          if (searchResults._links) payload.nextPage = searchResults._links.next
          dispatch(action.success(payload))
        })
        .catch((error) => {
          dispatch(action.error(error))
        })
    },
})

export const clearSearch = createAction('CLEAR_SEARCH', { noPayload: true })
export const lastSearchResult = createAction('LAST_SEARCH', (lastSearch) => lastSearch)
