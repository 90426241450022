import React from 'react'

import { Container } from '../index.js'

// eslint-disable-next-line import/no-anonymous-default-export, react/display-name
export default () => {
  return (
    <Container section>
      <h2>🙁 The resource you requested was not found</h2>
    </Container>
  )
}
