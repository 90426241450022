import { useSelector } from 'react-redux'
import React from 'react'
import IntegratedPlatform from '@nike/console-integrated-platform'
import {
  selectAccessToken,
  selectIsLoggedIn,
  selectLoginComplete,
  useAuthStore,
} from '@nike/aegis-auth-react'
import { useLocation } from 'react-router-dom'

import { setGlobalHeader } from '../util/http.js'
import { selectIsProduction } from '../navigation/reducer.js'
import { Link } from '../components/index.js'
import config from '../config.js'

export default function IntegratedPlatformConnected({ children }) {
  const isLoggedIn = useAuthStore(selectIsLoggedIn)
  const loginFinished = useAuthStore(selectLoginComplete)
  const accessToken = useAuthStore(selectAccessToken)
  const isProduction = useSelector(selectIsProduction)
  // setGlobalHeader('Authorization', 'Bearer ' + accessToken)

  let navBarLinks = isProduction
    ? [
        { text: 'Metrics', path: '/metrics' },
        { text: 'Search', path: '/search' },
        { text: 'Transaction Logs', path: '/transactions' },
      ]
    : [
        { text: 'Metrics', path: '/preprod/metrics' },
        { text: 'Search', path: '/preprod/search' },
        { text: 'Transaction Logs', path: '/preprod/transactions' },
      ]

  navBarLinks = navBarLinks.concat([
    {
      text: 'Docs',
      path: 'https://docs.platforms.nike.com/Entitlements',
      external: true,
    },
  ])

  const navBarConfig = {
    name: 'Entitlements',
    routes: navBarLinks,
    rootPath: '/',
    RouterLink: (props) => {
      const { pathname } = useLocation()
      const isHome = pathname === '/'
      const classname = props.href === pathname && !isHome ? 'active' : props.className
      return <Link {...props} to={props.href} className={classname} />
    },
  }

  if (!accessToken) {
    return null
  }

  return (
    <IntegratedPlatform
      showConsoleNav={true}
      techSolutionId={config.platformConsoleId}
      accessToken={accessToken}
      isLoggedIn={isLoggedIn}
      hasAuthCheckFinished={loginFinished}
      useConsoleLoginScreen={false}
      navBarConfig={navBarConfig}
      slackURL={`slack://channel?team=${config.slack.teamId}&id=${config.slack.channelId}`}
      name='Entitlements'
      env={config.platformConsoleEnv}
    >
      {children}
    </IntegratedPlatform>
  )
}
