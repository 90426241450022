import join from 'url-join'
import { asyncAction } from '@nike/redux-action-utils'

import { http } from '../util/index.js'
import config from '../config.js'

const apiUrl = join.bind(join, config.apiUrl, 'v1')

export const getEvaluations = asyncAction({
  baseName: 'EVALUATIONS_GET',
  action:
    (action) =>
    ({ filterKey, filterValue = '', environment } = {}) =>
    (dispatch) => {
      let params = {}
      if (filterKey) params[filterKey] = filterValue
      if (environment) params.env = environment

      dispatch(action.start())

      return http
        .get(apiUrl('metrics', 'evaluations'), params)
        .then((response) => {
          dispatch(action.success(response))
        })
        .catch((error) => {
          dispatch(action.error(error))
        })
    },
})

export const getStatusCodes = asyncAction({
  baseName: 'STATUS_CODES_GET',
  action:
    (action) =>
    ({ filterKey, filterValue = '', environment } = {}) =>
    (dispatch) => {
      let params = {}
      if (filterKey) params[filterKey] = filterValue
      if (environment) params.env = environment

      dispatch(action.start())

      return http
        .get(apiUrl('metrics', 'status-codes'), params)
        .then((response) => {
          dispatch(action.success(response))
        })
        .catch((error) => {
          dispatch(action.error(error))
        })
    },
})

export const getInvokeDurations = asyncAction({
  baseName: 'INVOKE_DURATIONS_GET',
  action:
    (action) =>
    ({ filterKey, filterValue = '', environment } = {}) =>
    (dispatch) => {
      let params = {}
      if (filterKey) params[filterKey] = filterValue
      if (environment) params.env = environment
      dispatch(action.start())

      return http
        .get(apiUrl('metrics', 'invoke-durations'), params)
        .then((response) => {
          dispatch(action.success(response))
        })
        .catch((error) => {
          dispatch(action.error(error))
        })
    },
})
