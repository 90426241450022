import { AegisClient } from '@nike/aegis-auth-react'

import config from '../config'

export const client = new AegisClient({
  qa: !config.isProd,
  restoreOriginalUri: (oktaAuth, originalUri) => {
    // SPA navigation and also is required to use disableTokenStorage
    if (!originalUri) {
      // Once the session times out, from what I can tell, there isn't a path stored, redirect to origin
      originalUri = oktaAuth.options.redirectUri
    }
    history.replaceState({}, '', originalUri)
  },
  pkce: true,
  ...config.oAuth,
})
