import { fromJS } from 'immutable'

import { getEntitlement, getEntitlementAudits } from './actions.js'

const defaultState = fromJS({
  entitlement: {},
  auditPager: {},
})

export default function entitlements(state = defaultState, action) {
  switch (action.type) {
    case getEntitlement.event.success:
      return state.set('entitlement', fromJS(action.payload))
    case getEntitlementAudits.event.success:
      return state.set(
        'auditPager',
        fromJS({
          items: action.payload.items,
          pageSize: action.payload.pageSize,
          from: action.payload.from || 0,
          totalItems: action.payload.totalItems,
        })
      )
    default:
      return state
  }
}

export const selectEntitlement = (state) => state.entitlements.get('entitlement')
export const selectAuditPager = (state) => state.entitlements.get('auditPager')
