/**
 * Nell-Hammer is an equal-area projection created by H.H. Ernst von Hammer (1900)
 * most of the cartographic distortion happens along the polar regions making it a good choice for displaying most continental land mass
 * more information at: https://proj.org/operations/projections/nell_h.html?highlight=projection
 *
 * *** this modules depends on d3 ***
 */

import { geoProjection as projection } from 'd3-geo'
import { abs, cos, epsilon, tan } from './math.js'

export function nellHammerRaw(lambda, phi) {
  return [(lambda * (1 + cos(phi))) / 2, 2 * (phi - tan(phi / 2))]
}

nellHammerRaw.invert = function (x, y) {
  let p = y / 2
  for (let i = 0, delta = Infinity; i < 10 && abs(delta) > epsilon; ++i) {
    const c = cos(y / 2)
    y -= delta = (y - tan(y / 2) - p) / (1 - 0.5 / (c * c))
  }
  return [(2 * x) / (1 + cos(y)), y]
}

export default function nellHammer() {
  return projection(nellHammerRaw).scale(152.63)
}
