import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Spinner } from '@nike/eds'
import { useSearchParams } from 'react-router-dom'
import qs from 'qs'

import { LoginRequired } from '../auth/index.js'
import { LinkButton, Button, Container, PageTitle } from '../components/index.js'
import { clearSearch, search, lastSearchResult } from './actions.js'
import { selectIsProduction } from '../navigation/reducer.js'
import { selectNextPage, selectSearchResults } from './reducer.js'
import { selectIsRequestPendingHook, selectRequestErrorHook } from '../util/requestReducer.js'
import { useEnvBasePathJoiner } from '../navigation/useEnvBasePath.js'
import SearchForm from './SearchForm.js'
import EnvSwitch from '../navigation/EnvSwitch.js'

const SearchResult = ({ result, updateLastSearch }) => {
  const dateString = result.createdAt
    ? moment(result.createdAt).format('dddd, MMMM Do YYYY, h:mmA')
    : 'N/A'

  const joinEnv = useEnvBasePathJoiner()

  return (
    <div className='card flex'>
      <div>
        <div>
          <strong>Owner:</strong> {result.owner}
        </div>
        <div>
          <strong>Namespace:</strong> {result.resource.namespace || 'N/A'}
        </div>
        <div>
          <strong>Created:</strong> {dateString}
        </div>
      </div>
      <div className='search-result-control'>
        <LinkButton
          to={joinEnv(`/entitlements/${result.id}`)}
          label='View Complete Policy'
          onClick={updateLastSearch}
        />
      </div>
    </div>
  )
}

const SearchPage = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const isProduction = useSelector(selectIsProduction)
  const searchResults = useSelector(selectSearchResults)
  const requestPending = useSelector(selectIsRequestPendingHook(search))
  const requestError = useSelector(selectRequestErrorHook(search))
  const lastResultCount = useSelector(lastSearchResult)
  const nextPage = useSelector(selectNextPage)

  const query = searchParams.get('query')

  useEffect(() => {
    dispatch(clearSearch())
  }, [isProduction])

  useEffect(() => {
    return () => dispatch(clearSearch())
  }, [])

  useEffect(() => {
    if (query) {
      dispatch(clearSearch())
      dispatch(search({ query }))
    }
  }, [query])

  const handleSubmit = ({ query }) => {
    setSearchParams((params) => {
      params.set('query', query)
    })
  }

  const handleLoadMore = () => {
    dispatch(search({ nextPage: nextPage }))
  }

  function renderSearchResults() {
    const loadMoreButtonDisabled = lastResultCount < 100
    if (requestPending && !searchResults) {
      return (
        <div>
          <Spinner size='large' className='center mt-2 mb-2' />
        </div>
      )
    } else if (searchResults) {
      return (
        <div>
          <p className='mb-1'>
            <em>
              Showing <strong>{searchResults.length}</strong> results for{' '}
              <strong>&quot;{query}&quot;</strong>
            </em>
          </p>
          {searchResults.map((result) => {
            return (
              <SearchResult
                key={`search-result-${result.id}`}
                result={result}
                updateLastSearch={() => dispatch(lastSearchResult(query))}
              />
            )
          })}
          <Button onClick={handleLoadMore} disabled={loadMoreButtonDisabled}>
            Load More
          </Button>
        </div>
      )
    }
  }

  return (
    <Container>
      <PageTitle title='Search - Entitlements Dashboard' />
      <EnvSwitch isProduction={isProduction} />
      <SearchForm query={qs.stringify(query)} onSubmit={handleSubmit} />
      {renderSearchResults()}
    </Container>
  )
}

export default (props) => {
  return (
    <LoginRequired>
      <SearchPage {...props} />
    </LoginRequired>
  )
}
