import { setGlobalHeader } from '../util/http.js'
import { notifyError } from '../notifications/actions.js'
import { client } from './client.js'

export function initAuthEvents() {
  const resetAuth = () => {
    setGlobalHeader('Authorization', null)
  }

  client.on('login:expired', () => {
    resetAuth()
  })

  client.on('login:success', ({ accessToken }) => {
    setGlobalHeader('Authorization', 'Bearer ' + accessToken.accessToken)
  })

  client.on('renew:access:success', (accessToken) => {
    setGlobalHeader('Authorization', 'Bearer ' + accessToken.accessToken)
  })

  client.on('renew:error', (error) => {
    if (error) {
      notifyError({ message: loginError })
    }
    resetAuth()
  })

  client.on('login:error', (error) => {
    if (error) {
      notifyError({ message: loginError })
    }
  })

  client.on('logout:success', () => {
    resetAuth()
  })
}
