import React from 'react'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import { standardizeTimeByHalfHour } from '../util/index.js'

const chartMargin = { top: 5, right: 0, bottom: 5, left: 5 }

const processData = (data) => {
  let processedData = []

  let startDateTime = standardizeTimeByHalfHour(moment().subtract(24, 'hours'))

  for (let x = 0; x < data[0].timeSeries.length; x++) {
    processedData.push({
      name: startDateTime
        .clone()
        .add(30 * x, 'minutes')
        .format('HH:mm'),
      invoke_duration: data[0].timeSeries[x],
    })
  }

  return processedData
}

export default function TimeSeriesChart({ data, tooltipValue, unit, yLabel }) {
  if (!data) {
    return null
  }

  let processedData = processData(data, tooltipValue)

  return (
    <ResponsiveContainer aspect={2.5}>
      <LineChart data={processedData} margin={chartMargin} syncId='metrics'>
        <XAxis dataKey='name' interval='preserveStartEnd' />
        <YAxis label={{ value: yLabel, angle: -90, offset: 0, position: 'insideLeft' }} />
        <Tooltip formatter={(value) => tooltipValue(value)} />
        <Legend verticalAlign='bottom' height={36} />
        <Line
          type='monotone'
          dataKey='invoke_duration'
          legendType='square'
          name='Invoke Duration'
          unit={unit}
          stroke='#8884d8'
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
